
import { computed, defineComponent, ref, watchEffect } from 'vue';
import CartItems from '../components/CartItems.vue';
import {
  SEND_CART_BUYS,
  SEND_FREIGHT_DETAILS,
GET_ADDRESSES
} from '@/store/type-actions';
import { useStore } from '@/store';
import { db } from '@/firebase';
import { query, collection, onSnapshot, getDocs, deleteDoc, doc } from 'firebase/firestore';
import ModalPayments from '../components/ModalPayments.vue';
import { PIX_QR_CODE } from '@/store/type-mutations';
import Input from '../components/Input.vue'
import { brazilianStates } from '../helpers/brazilianStates'
import { Icon } from '@iconify/vue';
import ModalAddress from '../components/ModalAddress.vue'

export default defineComponent({
  name: 'buyingScreen',
  components: {
    CartItems,
    ModalPayments,
    Input,
    Icon,
    ModalAddress
  },

  data() {
    return {
      messageFailPayment: '',
      creditCardChoice: false,
      pixChoice: false,
      eCashChoice: false,
      showAddress: false,
      months: [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12'
      ],
      address: {
        city: '',
        neighborhood: '',
        number: '',
        state: '',
        street: '',
        zipCode: '',
      },
      cartItems: [
          {
            product_id: '',
            colorId: 0,
            color: 0,
            price: 0,
            product_option: 0,
            promotional_price: '',
            size: '',
          },
        ],
      loading: 'waiting',
      totalPrice: 0,
      dataEcash: '00,00',
      dataSaldo: '00,00',
      dataPix: '00,00',
      dataCreditCard: '00,00',
      cep: '',
      selectedFreight: '',
      freightName: '',
      freightOptions: [
        {
          Codigo: '',
          Nome: '',
          Prazo: 0,
          Valor: 0,
        },
      ],
      selectedAddress: {
        type: '',
        id: 0
      },
      prompt: 0,
      eCashSaldo: false,
      showPaymentResult: 'waiting',
      loadingPayment: true,
      restartingQR: false,
      firebase: process.env.VUE_APP_FIREBASE_STORE || 'CartItemsHomologacao',
      pixTimer: process.env.VUE_APP_PIX_TIMER || 0,
      states:  brazilianStates,
   };
  },

  methods: {

    getStateAcronym(state: string): string {
      return this.states.find((stt: { state: string, acronym: string }) => stt.state.toLowerCase() == state)?.acronym || ''
    },

   checkCreditCardForm(): boolean {
      
      if (this.form.typeCard == '') {
        this.errors.typeCard = 'Informe o tipo do cartão'
      } else {
        this.errors.typeCard = ''
      }

      if (!this.cardInfo[0].value.match(/[Àa-üZ]+\s[Àa-üZ\s]+/i)) {
        this.cardInfo[0].error = 'Informe nome e sobrenome'
      } else {
        this.cardInfo[0].error = ''
      }

      if (!this.cardInfo[1].value.match(/[\d]{3}\.[\d]{3}\.[\d]{3}-[\d]{2}/)) {
        this.cardInfo[1].error = 'Informe o cpf corretamente'
      } else {
        this.cardInfo[1].error = ''
      }

      if (!this.cardInfo[2].value.match(/[\d]{4}\s[\d]{4}\s[\d]{4}\s[\d]{4}/)) {
      this.cardInfo[2].error = 'Informe o número do cartão corretamente'
      } else {
        this.cardInfo[2].error = ''
      }

      if (!this.cardInfo[3].value.match(/[\d]{3}/)) {
        this.cardInfo[3].error = 'Informe o CVV do cartão'
      } else {
        this.cardInfo[3].error = ''
      }

      if (!this.form.month.match(/[\d]{2}/)) {
        this.errors.month = 'Informe o mês de vencimento do cartão'
      } else {
        this.errors.month = ''
      }

      if (!this.form.year.match(/[\d]{2}/)) {
        this.errors.year = 'Informe o ano de vencimento do cartão'
      } else {
        this.errors.year = ''
      }

      if (!this.personalInfo[0].value.match(/[Àa-üZ0-9_.+-]+@[Àa-üZ0-9_.+-].[Àa-üZ0-9_.+-]/gim)) {
        this.personalInfo[0].error = 'Informe um email válido'
      } else {
        this.personalInfo[0].error = ''
      }

      if (!this.personalInfo[1].value.match(/\(\d\d\)\s9\s\d{4}-\d{4}/)) {
        this.personalInfo[1].error = 'Informe um telefone válido'
      } else {
        this.personalInfo[1].error = ''
      }

      if (!this.form.cep.match(/\d{5}-\d{3}/)) {
        this.errors.cep = 'Informe o cep corretamente'
      } else {
        this.errors.cep = ''
      }

      if (this.form.state == '') {
        this.errors.state = 'Informe um estado'
      } else {
        this.errors.state = ''
      }

      if (this.personalInfo[2].value == '') {
        this.personalInfo[2].error = 'Informe uma cidade'
      } else {
        this.personalInfo[2].error = ''
      }

      if (this.personalInfo[3].value == '') {
        this.personalInfo[3].error = 'Informe um bairro'
      } else {
        this.personalInfo[3].error = ''
      }

      if (this.personalInfo[4].value == '') {
        this.personalInfo[4].error = 'Informe uma rua'
      } else {
        this.personalInfo[4].error = ''
      }

      if (this.personalInfo[5].value == '') {
        this.personalInfo[5].error = 'Informe um número residencial'
      } else {
        this.personalInfo[5].error = ''
      }

      if (this.personalInfo[5].value == '' ||
      this.personalInfo[4].value == ''||
      this.personalInfo[3].value == '' ||
      this.personalInfo[2].value == '' ||
      this.form.state == ''||
      !this.form.cep.match(/\d{5}-\d{3}/) ||
      !this.personalInfo[1].value.match(/\(\d\d\)\s9\s\d{4}-\d{4}/) ||
      !this.personalInfo[0].value.match(/[Àa-üZ0-9_.+-]+@[Àa-üZ0-9_.+-].[Àa-üZ0-9_.+-]/gim) ||
      !this.form.year.match(/[\d]{2}/) ||
      !this.form.month.match(/[\d]{2}/) ||
      !this.cardInfo[3].value.match(/[\d]{3}/) ||
      !this.cardInfo[2].value.match(/[\d]{4}\s[\d]{4}\s[\d]{4}\s[\d]{4}/) ||
      !this.cardInfo[1].value.match(/[\d]{3}\.[\d]{3}\.[\d]{3}-[\d]{2}/) ||
      !this.cardInfo[0].value.match(/[Àa-üZ]+\s[Àa-üZ\s]+/i) ||
      this.form.typeCard == '') {
        return false
      }

      return true
    },

    getAddressCadastro(payload: any) {
      this.selectedAddress = {type: 'cadastro', id: payload.id}
      this.cep = payload?.zip_code || ''
      this.calculateFreight()

      this.address = {
        city: payload?.city || '',
        state: payload?.state?.toLowerCase() || '',
        neighborhood: payload?.neighborhood || '',
        street: payload?.street || '',
        zipCode: payload?.zipCode || '',
        number: payload?.number || ''
      }
    },

    getAddressEntrega(payload: any) {
      this.selectedAddress = {type: 'entrega', id: payload.id}
      this.cep = payload?.zip_code || ''
      this.calculateFreight()
      this.address = {
        city: payload?.city || '',
        state: payload?.state?.toLowerCase() || '',
        neighborhood: payload?.neighborhood || '',
        street: payload?.street || '',
        zipCode: payload?.zipCode || '',
        number: payload?.number || ''
      }
    },

    checkForm(): boolean {
      this.messageFailPayment = '';
      let eCash = parseFloat(this.dataEcash.replaceAll('.','').replace(',', '.')) || 0;
      let pix = parseFloat(this.dataPix.replaceAll('.','').replace(',', '.')) || 0;
      let creditCard = parseFloat(this.dataCreditCard.replaceAll('.','').replace(',', '.')) || 0;

      if (eCash + pix + creditCard > this.totalPrice) {
        const exceededValue = new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                }).format((eCash + pix + creditCard) - this.totalPrice)

                this.messageFailPayment = `Valor do pagamento não confere. Valor ultrapassado ${exceededValue}`
        return false
      }

      if (eCash + pix + creditCard < this.totalPrice) {
        const missingValue = new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                }).format(this.totalPrice - (eCash + pix + creditCard))
     
        this.messageFailPayment = `Valor do pagamento não confere. Valo em falta ${missingValue}`
        return false
      }
  
      if (creditCard != 0) {
        return this.checkCreditCardForm()
      }
      return true
    },

    showMissingValue(): string {
      let eCash = parseFloat(this.dataEcash.replaceAll('.','').replace(',', '.')) || 0;
      let pix = parseFloat(this.dataPix.replaceAll('.','').replace(',', '.')) || 0;
      let creditCard = parseFloat(this.dataCreditCard.replaceAll('.','').replace(',', '.')) || 0;
      
      if (eCash + pix + creditCard <= this.totalPrice) {
      const missingValue = new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                }).format(this.totalPrice - (eCash + pix + creditCard))


        return `Valor em falta ${missingValue}`
      }

      if (eCash + pix + creditCard > this.totalPrice) {

        const exceededValue = new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                }).format((eCash + pix + creditCard) - this.totalPrice)


        return `Valor ultrapassado ${exceededValue}`
      }

      return ''
    },

    async finishPayment() {
      const eCash = parseFloat(this.dataEcash.replaceAll('.','').replace(',', '.')) || 0;
      const pix = parseFloat(this.dataPix.replaceAll('.','').replace(',', '.')) || 0;
      const creditCard = parseFloat(this.dataCreditCard.replaceAll('.','').replace(',', '.')) || 0;

      let payments: unknown[] = [];

      if (eCash + pix + creditCard !== this.totalPrice) {
        this.errors.valueError = 'Os valores não batem';
      }

      if (eCash != 0) {
        if ((eCash > this.eCashValue) && (this.eCashValue !== 0)) {
          payments.push({
            payment_type: 1,
            payment_value: this.eCashValue,
          });

          payments.push({
            payment_type: 4,
            payment_value: eCash - this.eCashValue,
          });
        }

        if (this.eCashValue == 0) {
          payments.push({
            payment_type: 4,
            payment_value: eCash,
          });
        }

        if (eCash < this.eCashValue) {
          payments.push({
            payment_type: 1,
            payment_value: eCash,
          });
        }
      }

      if (pix != 0) {
        payments.push({
          payment_type: 2,
          payment_value: pix,
        });
      }

      if (creditCard != 0) {
        payments.push({
          payment_type: 3,
          payment_value: creditCard,
          card_type: this.form.typeCard,
          card_owner_name: this.cardInfo[0].value,
          cpf: this.cardInfo[1].value,
          card_number: this.cardInfo[2].value,
          cvv: this.cardInfo[3].value,
          validate_month: this.form.month,
          validate_year: this.form.year,
          personal_data: [
            {
              email: this.personalInfo[0].value,
              number: this.personalInfo[1].value,
              cep: this.form.cep,
              state: this.getStateAcronym(this.form.state),
              city: this.personalInfo[2].value,
              neighborhood: this.personalInfo[3].value,
              street: this.personalInfo[4].value,
              residence_number: this.personalInfo[5].value,
              complement: this.personalInfo[6].value,
            },
          ],
        });
      }

      if (this.checkForm()) {
        const q = query(collection(db, this.firebase + this.loginId));
        const getAllItems = await getDocs(q);
        let items: Array<any> = [];
        let item1 = {};

        getAllItems.forEach(async (item: { data(): unknown }) => {
          let parsed = JSON.parse(JSON.stringify(item.data()));

          item1 = {
            product_id: parsed.product_id,
            product_option: parsed.product_option,
            quantity: parsed.quantity,
            value: parsed.price,
            color: parsed.color_id,
            size: parsed.size,
            colorNumber: parsed.color
          };
          items.push(item1);
        });

        const cart = {
          user_id: this.loginId,
          final_value: this.totalPrice,
          products_value: this.finalProductPrice,
          client_payment: pix + creditCard + eCash,
          send: JSON.parse(JSON.stringify(this.selectedAddress)),
          products: items,
          freight: {
            freight_value: this.fretePrice,
            prompt: this.prompt,
            freight_code: this.selectedFreight,
            freight_name: this.freightName,
          },
          payments_all: [
            {
              payment: payments,
            },
          ],
        };

        this.loadingPayment = true;
        this.showPaymentResult = ''
        
        try {
         const cartPayload = await this.store.dispatch(SEND_CART_BUYS, cart).then((res) => res.data.data)
          console.log(cartPayload)
          if (cartPayload?.pix) {
          const pix = JSON.parse(cartPayload.pix[0])
          console.log(pix)
          this.showPaymentResult = 'pix'; 

          this.loadingPayment = false; 
          this.store.commit(PIX_QR_CODE, {
          status: pix.status,
          qrCode: pix.qr_code,
          price: pix.price,
          id: pix.transaction_id,
          timer: this.pixTimer
          })

        } else if (cartPayload?.card?.approved) {
          
         items.forEach(async (item) => {
              await deleteDoc(
                doc(
                  db,
                  this.firebase + this.loginId,
                  item.product_id + item.colorNumber + item.size))
              })
          this.showPaymentResult = 'success';
          this.loadingPayment = false;
        } else if (cartPayload?.enerzee?.status == 200) {
         items.forEach(async (item) => {
                await deleteDoc(
                doc(
                  db,
                  this.firebase + this.loginId,
                  item.product_id + item.colorNumber + item.size))
              })
        this.showPaymentResult = 'success';
          this.loadingPayment = false;
        } else {
          this.showPaymentResult = 'error';
          this.loadingPayment = false
       }

        }catch(err) {
         this.showPaymentResult = 'error';
          this.loadingPayment = false
        }

     }

    },

    eCashMask() {
      if (this.dataEcash != '') {
        let oldValueCash = this.dataEcash
          .replaceAll('.', '')
          .replaceAll(',', '');
        let checkCash = oldValueCash.replace(/^0\d+/, oldValueCash.substr(1));
        let eCashComma = checkCash.replace(/(\d{2})$/gim, ',$1');
        let newValueCash = eCashComma.replace(
          /\B(?=(\d{3})+(?!\d)(?=,))/g,
          '.'
        );
        this.dataEcash = newValueCash;
      } else {
        this.dataEcash = '00,00';
      }
    },

    pixMask() {
      if (this.dataPix != '') {
        let oldValuePix = this.dataPix.replaceAll('.', '').replaceAll(',', '');
        let checkPix = oldValuePix.replace(/^0\d+/, oldValuePix.substr(1));
        let pixComma = checkPix.replace(/(\d{2})$/gim, ',$1');
        let newValuePix = pixComma.replace(/\B(?=(\d{3})+(?!\d)(?=,))/g, '.');

        this.dataPix = newValuePix;
      } else {
        this.dataPix = '00,00';
      }
    },

    creditCardMask() {
      if (this.dataCreditCard !== '') {
        let oldCreditCard = this.dataCreditCard
          .replaceAll('.', '')
          .replaceAll(',', '');
        let checkCreditCard = oldCreditCard.replace(
          /^0\d+/,
          oldCreditCard.substr(1)
        );
        let creditCardComma = checkCreditCard.replace(/(\d{2})$/gim, ',$1');
        let newCreditCardValue = creditCardComma.replace(
          /\B(?=(\d{3})+(?!\d)(?=,))/g,
          '.'
        );

        this.dataCreditCard = newCreditCardValue;
      } else {
        this.dataCreditCard = '00,00';
      }
    },
     
     getCartProducts() {
      const q = query(collection(db, this.firebase + this.loginId));

      onSnapshot(q, (query) => {
        this.totalPrice = 0
        this.finalProductPrice = 0
        this.cartItems = [
          {
            product_id: '',
            colorId: 0,
            color: 0,
            price: 0,
            product_option: 0,
            promotional_price: '',
            size: '',
          },
        ];
        query.forEach((doc) => {

        const parsed = JSON.parse(JSON.stringify(doc.data()));
            const price = parseFloat(parsed.price) || 0;
            this.totalPrice += price;
            this.finalProductPrice += price;
          this.cartItems.push(JSON.parse(JSON.stringify(doc.data())));
        });
      });
    },

    closePixPayment() {
      if (this.showPaymentResult == 'success') {
        this.$router.push('/')
      }
      this.showPaymentResult = 'waiting';
    },

    async calculateFreight() {
      const q = query(collection(db, this.firebase + this.loginId));
      const getAllItems = await getDocs(q);
      let freightItems = [{}];

      this.freightOptions = [
        {
          Codigo: '',
          Nome: '',
          Prazo: 0,
          Valor: 0,
        },
      ];

      getAllItems.forEach((doc: { data(): unknown }) => {
        this.loading = 'loading';
        let parsed = JSON.parse(JSON.stringify(doc.data())); 
        let item = {
          quantidade: parsed.quantity,
          peso: parsed.weight,
          comprimento: parsed.comprimento,
          altura: parsed.height,
          largura: parsed.width,
        };
        freightItems.push(item);
      });

      try {
        await this.store
          .dispatch(SEND_FREIGHT_DETAILS, {
            cep_destino: this.cep.replace('-', ''),
            item: freightItems.slice(1),
          })
          .then((res) => {
            for (let rrr = 0; rrr < res.data.length; rrr++) {
              const data = res.data[rrr];
              this.freightOptions.push(data);
              this.loading = 'loaded';
            }
          });
      } catch (err: unknown) {
        err;
      }
    },
    getSelectedFreight(
      value: number,
      selectedFreight: string,
      prompt: number,
      name: string
    ) {   
      localStorage.setItem('freightValue', value.toString());
      localStorage.setItem('finalProductPriceValue', this.finalProductPrice.toString());
      this.fretePrice = value;
      this.selectedFreight = selectedFreight;
      this.prompt = prompt;
      this.freightName = name;
      this.calculateFinalPrice();
    },

    calculateFinalPrice() {
      // Get the saved freight value from localStorage
      const freightValue = localStorage.getItem('freightValue');
      const finalProductPriceValue = localStorage.getItem('finalProductPriceValue');

      if (freightValue && finalProductPriceValue) {
      const parsedFreightValue = parseFloat(freightValue).toFixed(2);
   
      const parsedFinalProductPriceValue = parseFloat(finalProductPriceValue).toFixed(2);
      const FinallyPrice = parseFloat(freightValue) + parseFloat(finalProductPriceValue);
      this.totalPrice = (FinallyPrice); // Recalculate the total price by adding the freight value in monetary format
      localStorage.removeItem('freightValue');
      }
    },

    getYears() {
      const date = new Date()
      const dates = [
        date.getFullYear(),
        date.getFullYear() + 1,
        date.getFullYear() + 2,
        date.getFullYear() + 3,
        date.getFullYear() + 4,
        date.getFullYear() + 5,
        date.getFullYear() + 6,
        date.getFullYear() + 7,
        date.getFullYear() + 8,
        date.getFullYear() + 9,
        date.getFullYear() + 10,
      ]

      return dates
    }
  },

  async mounted() {
    watchEffect(() => {
      if (!this.creditCardChoice) {
        this.dataCreditCard = '00,00';
      }

      if (!this.pixChoice) {
        this.dataPix = '00,00';
      }

      if (!this.eCashChoice) {
        this.dataEcash = '00,00';
      }

      const eCash = parseFloat(this.dataEcash.replaceAll('.','').replace(',', '.')) || 0;

      if (eCash > this.userSaldo + this.eCashValue) {
          this.dataEcash = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
          }).format(this.userSaldo + this.eCashValue).replace('R$','')
      }

    }); 
    this.getCartProducts(); 
    this.cep = this.user.CEP 
    await this.calculateFreight()
    await this.store.dispatch(GET_ADDRESSES, this.loginId)
    const userAdr = this.store.state.userAddresses.endereco_cadastro
    this.selectedAddress = {type: 'cadastro', id: userAdr.id}

    this.address = {
      city: userAdr?.city || '',
      state: userAdr?.state?.toLowerCase() || '',
      neighborhood: userAdr?.neighborhood || '',
      street: userAdr?.street || '',
      zipCode: userAdr?.zipCode || '',
      number: userAdr?.number || ''
    }
  },

  setup() {
    const store = useStore();
    const user = store.state.user
    const finalProductPrice = ref(0);
    const fretePrice = ref(0);
    const eCashValue = ref(user.SaldoECash || 0);
    const userSaldo = ref(user.Saldo || 0)
    const loginId = store.state.login.data.id
    const cardInfo = ref([
         {
          label: 'Nome e Sobrenome',
          placeholder: 'Nome e Sobrenome',
          mask: '',
          id: 'nameAndSurname',
          value: '',
          error: '',
          divStyle: 'flex flex-col w-full gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',
        },
        {
          label: 'CPF do cartão',
          placeholder: '000.000.000-00',
          mask: '###.###.###-##',
          id: 'cardCPF',
          value: '',
          error: '',
          divStyle: 'flex flex-col w-full gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',

        },
        {
          label: 'Número do cartão',
          placeholder: 'Número do cartão',
          mask: '#### #### #### ####',
          id: 'cardNumber',
          value: '',
          error: '',
          divStyle: 'flex flex-col w-9/12 gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',

        },
        {
          label: 'CVV',
          placeholder: 'CVV',
          mask: '###',
          id: '123',
          value: '',
          error: '',
          divStyle: 'flex flex-col w-2/12 gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',
        }
      ])

     const personalInfo = ref([
        {
          label: 'Email',
          placeholder: 'email@exemplo.com',
          mask: '',
          id: 'email',
          value: '',
          error: '',
          divStyle: 'flex flex-col w-full gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',
        },
        {
          label: 'Número',
          placeholder: '(00) 9 0000-0000',
          mask: '(##) 9 ####-####',
          id: 'cellphone',
          value: '',
          error: '',
          divStyle: 'flex flex-col w-full gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',
        },
        {
          label: 'Cidade',
          placeholder: 'Cidade',
          mask: '',
          id: 'city',
          value: user.NomeCidade,
          error: '',
          divStyle: 'flex flex-col w-1/2 gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',
        },
        {
          label: 'Bairro',
          placeholder: 'Bairro',
          mask: '',
          id: 'neighborhood',
          value: user.Bairro,
          error: '',
          divStyle: 'flex flex-col w-1/2 gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',
        },
        {
          label: 'Rua',
          placeholder: 'Rua',
          mask: '',
          id: 'street',
          value: user.Endereco,
          error: '',
          divStyle: 'flex flex-col w-1/2 gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',
        },
        {
          label: 'Número de residência',
          placeholder: 'Número',
          mask: '',
          id: 'residenceNumber',
          value: user.Numero,
          error: '',
          divStyle: 'flex flex-col w-1/2 gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',
        },
        {
          label: 'Complemento',
          placeholder: 'Complemento',
          mask: '',
          id: 'complement',
          value: user.Complemento,
          divStyle: 'flex flex-col w-full gap-1 break-word',
          inputStyle: 'p-1 border-2 mx-1 mb-2 focus:border-azul-claro focus:outline-none',
        },

      ])

      const form = ref({
        typeCard: '',
        month: '',
        year: '',
        cep: user.CEP,
        state: user.NomeEstado.toLowerCase(),
      })

      const errors = ref({
        valueError: '',
        typeCard: '',
        month: '',
        year: '',
        cep: '',
        state: '',
      })
  
    return {
      store,
      user,
      loginId,
      finalProductPrice,
      fretePrice,
      eCashValue,
      userSaldo,
      cardInfo,
      personalInfo,
      form,
      errors
    };
  },
});
